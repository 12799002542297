import React, { useEffect, useState } from "react";
import { LogoSVG } from "../../SVG/logoSVG";
import { onLoadingFonts } from "../../Constants/constants";

export const RedirectScreen = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [progress, setProgress] = useState();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://www.arevera.global";
    }, 3000);
  }, [fontsLoaded]);

  useEffect(() => {
    if (!fontsLoaded) {
      onLoadingFonts(setFontsLoaded, progress, setProgress);
    }
  }, [fontsLoaded]);

  return (
    <>
      {!fontsLoaded ? null : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px" /*  */,
            background: "#f5f4f2",
          }}
        >
          <LogoSVG />
          <p
            style={{
              width: "457px",
              height: "52px",
              textAlign: "center",
              transform: "translate(-50%, -50%,)",
              fontSize: "22px",
              fontWeight: 500,
              lineHeight: "26px",
              fontFamily: "SF-Pro400",
            }}
          >
            Bipolar Studio is now officially Arevera. You are being{" "}
            <span
              style={{
                fontFamily: "SF-Pro600",
                fontWeight: 600,
              }}
            >
              redirected
            </span>
            . Please, wait a while.
          </p>
        </div>
      )}
    </>
  );
};
