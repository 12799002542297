import FontFaceObserver from "fontfaceobserver";

export const onLoadingFonts = (setFontsLoaded, progress, setProgress) => {
  const fonts = ["SF-Pro300", "SF-Pro400", "SF-Pro500", "SF-Pro600"].map(
    (fontName) => new FontFaceObserver(fontName)
  );

  let loadedFonts = 0;
  const totalFonts = fonts.length;
  progress >= 100 && setProgress(0);

  fonts.forEach((font) => {
    font
      .load()
      .then(() => {
        loadedFonts += 1;
        setProgress((loadedFonts / totalFonts) * 100);
      })
      .catch(() => {
        console.error("Font failed to load");
      });
  });

  Promise.all(fonts.map((font) => font.load()))
    .then(() => setFontsLoaded(true))
    .catch(() => {
      console.error("One or more fonts failed to load");
      setFontsLoaded(true);
    });
};
